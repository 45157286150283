
import appConfig from 'configs/app.config'  

import { signInUserData } from './data/authData'

import { authFakeApi } from './fakeApi'

const { apiPrefix } = appConfig

export default function mockServer() {

}